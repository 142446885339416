import { React, useState } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'

import Layout from '../components/Layout'

//mui
import { Button, Grid, TextField, Typography } from '@mui/material'

//auth
import { useAuth } from '../contexts/AuthContext'

export default function Login() {
  const auth = useAuth()
  const location = useLocation()

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const STATE_USER_INNPUT_ALLOWED = 0 //form to accept inputs
  const STATE_LOGGING_IN = 1 //POST request is sent, waiting for response
  const STATE_EMAIL_SENT = 2 //POST request sent and OK received

  //title texts for each state
  const title = {}
  title[STATE_USER_INNPUT_ALLOWED] = 'Please login with your email account'
  title[STATE_LOGGING_IN] = 'Please wait while we are logging you in...'
  title[STATE_EMAIL_SENT] =
    'To complete login, please check your email and follow sent instructions.'

  const [state, setState] = useState(STATE_USER_INNPUT_ALLOWED)

  const handleLogin = () => {
    let body = {
      email,
    }

    setState(STATE_LOGGING_IN)

    axios
      .post('/login', body)
      .then((response) => {
        setError('')
        window.localStorage.setItem('emailForSignIn', email)
        let path =
          location.state && location.state.from
            ? location.state.from.pathname
            : '/'

        console.log(path)
        return auth.sendLoginLink(
          email,
          process.env.REACT_APP_TARGET === 'production'
            ? process.env.REACT_APP_PROD_LOGIN_LANDING_PAGE +
                '/LoginFinish?from=' +
                encodeURIComponent(path)
            : process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_LOC_LOGIN_LANDING_PAGE +
              '/LoginFinish?from=' +
              encodeURIComponent(path)
            : process.env.REACT_APP_DEV_LOGIN_LANDING_PAGE +
              '/LoginFinish?from=' +
              encodeURIComponent(path)
        )
      })
      .then(() => {
        setState(STATE_EMAIL_SENT)
      })
      .catch((error) => {
        setError(error.message)
        setState(STATE_USER_INNPUT_ALLOWED)
      })
  }

  return (
    <Layout>
      <Grid container style={{ margin: '40px auto 40px auto' }}>
        <Grid item xs={1} sm={3} md={4} />
        <Grid item xs={10} sm={6} md={4}>
          <Typography
            variant="body1"
            color="textPrimary"
            align="center"
            style={{ margin: '10px auto 20px auto' }}
          >
            {title[state]}
          </Typography>
          {state === STATE_USER_INNPUT_ALLOWED && (
            <>
              <TextField
                name="details_total_load"
                value={email}
                label="email"
                fullWidth
                error={error !== ''}
                helperText={error}
                onChange={(event) => {
                  setEmail(event.target.value)
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                fullWidth
                style={{ margin: '40px auto 20px auto' }}
                onClick={handleLogin}
              >
                Login
              </Button>
            </>
          )}
        </Grid>
        <Grid item xs={1} sm={3} md={4} />
      </Grid>
    </Layout>
  )
}
